import React from "react";
import "./style.scss";

const SubPageHead = ({ title }) => {
  return (
    <React.Fragment>
      <div className="subpage-head" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./hero.jpg")` }}>
        <h1>{title}</h1>
      </div>
    </React.Fragment>
  );
};

export default SubPageHead;
