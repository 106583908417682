import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import WordCount from "@ckeditor/ckeditor5-word-count";
import React from "react";

export default function CkEditor({ onChange, body }) {
  return (
    <React.Fragment>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: ["heading", "|", "bold", "italic", "bulletedList", "numberedList"],
          // plugins: [WordCount],
          heading: {
            options: [
              { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
              { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
              { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
            ],
          },
        }}
        data={body || ""}
        onInit={(editor) => {
          // const wordCountPlugin = editor.plugins.get("WordCount");
          // console.log(wordCountPlugin.words);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          if (onChange) onChange(data);
        }}
      />
    </React.Fragment>
  );
}
