import React from "react";
import { Button, Form, Select, Input } from "antd";

export default function GeneralDetails({ onNext, inputs, data }) {
  const [form] = Form.useForm();
  const onOk = (val) => {
      const applying_for = `1-${val.applying_for1}, 2-${val.applying_for2}, 3-${val.applying_for3}, 4-${val.applying_for4}`;
    onNext({...val,applying_for});

    // console.log(val)
  };

  React.useEffect(() => {
    form.setFieldsValue({ ...inputs });
    if (!inputs.applying_for) {
      // form.setFieldsValue({ applying_for: data[0].name });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Form onFinish={onOk} form={form} >
      <header>
        <h1>
          <b>General Details</b>
        </h1>
        <p>Your personal and general info</p>
      </header>
      <br />
      <div style={{
        width:'500px'
      }}>
      <Form.Item
          label="Applying For (choose priority 01)"
          className="full"
          name="applying_for1"

          rules={[
            {
              required: true,
              message: "Applying for is required",
            },
          ]}
        >
          <Select placeholder="Select Program">
            {data.map((item) => (
              <Select.Option key={item._id} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      <Form.Item
          label="Applying For (choose priority 02)"
          className="full"
          name="applying_for2"


        >
          <Select placeholder="Select Program">
            {[...data,{_id: 999, name: 'N/A'}].map((item) => (
              <Select.Option key={item._id} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      {/* <Form.Item
          label="Applying For (choose priority 03)"
          className="full"
          name="applying_for3"


        >
          <Select placeholder="Select Program">
            {[...data,{_id: 999, name: 'N/A'}].map((item) => (
              <Select.Option key={item._id} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
      {/* <Form.Item
          label="Applying For (choose priority 04)"
          className="full"
          name="applying_for4"


        >
          <Select placeholder="Select Program">
            {[...data,{_id: 999, name: 'N/A'}].map((item) => (
              <Select.Option key={item._id} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
      </div>
      <div className="two-item">

        <Form.Item
          label="Category"
          className="full"
          name="category"
          rules={[
            {
              required: true,
              message: "Category is required",
            },
          ]}
        >
          <Select placeholder="Select Category">
            <Select.Option value="Regular">Regular</Select.Option>
            <Select.Option value="Corporate Sponsor">Corporate Sponsor</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="three-item">
        <Form.Item
          label="First Name"
          className="full"
          name="first_name"
          rules={[
            {
              required: true,
              message: "First name is required",
            },
          ]}
        >
          <Input placeholder="eg. Ram"></Input>
        </Form.Item>
        <Form.Item label="Middle Name" className="full" name="middle_name">
          <Input placeholder="eg. Prasad"></Input>
        </Form.Item>
        <Form.Item
          label="Last name"
          className="full"
          name="surname"
          rules={[
            {
              required: true,
              message: "Last name is required",
            },
          ]}
        >
          <Input placeholder="eg. Neupane"></Input>
        </Form.Item>
      </div>
      <div className="two-item">
        <Form.Item
          label="Gender"
          className="full"
          name="gender"
          rules={[
            {
              required: true,
              message: "Gender is required",
            },
          ]}
        >
          <Select placeholder="Select Gender">
            <Select.Option value="Male">Male</Select.Option>
            <Select.Option value="Female">Female</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Marital Status"
          className="full"
          name="marital_status"
          rules={[
            {
              required: true,
              message: "Marital status is required",
            },
          ]}
        >
          <Select placeholder="Select Marital Status">
            <Select.Option value="Single">Single</Select.Option>
            <Select.Option value="Married">Married</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="two-item">
        <Form.Item
          label="Date of birth (AD) | YYYY-MM-DD"
          className="full"
          name="dob_ad"
          required
          rules={[
            () => ({
              validator(_, value) {
                const checkFormat = value.split("-");
                console.log(checkFormat);
                if (checkFormat.length > 2) {
                  return Promise.resolve();
                }
                return Promise.reject("Date of birth (AD) must be valid");
              },
            }),
          ]}
        >
          <Input placeholder="eg. 1999-04-25"></Input>
        </Form.Item>

        <Form.Item
          label="Date of birth (BS) | YYYY-MM-DD"
          className="full"
          name="dob_bs"
          required
          rules={[
            () => ({
              validator(_, value) {
                const checkFormat = value.split("-");
                if (checkFormat.length > 2) {
                  return Promise.resolve();
                }
                return Promise.reject("Date of birth (BS) must be valid");
              },
            }),
          ]}
        >
          <Input placeholder="eg. 2055-01-12"></Input>
        </Form.Item>
      </div>
      <div className="two-item">
        <Form.Item
          label="Guardian Name"
          className="full"
          name="guardian_name"
          rules={[
            {
              required: true,
              message: "Guardian name is required",
            },
          ]}
        >
          <Input placeholder="eg. Ram Prasad Neupane"></Input>
        </Form.Item>

        <Form.Item
          label="Guardian Profession"
          className="full"
          name="guardian_profession"
          rules={[
            {
              required: true,
              message: "Guardian profession is required",
            },
          ]}
        >
          <Input placeholder="eg. Businessman"></Input>
        </Form.Item>
      </div>
      <div className="three-item">
        <Form.Item
          label="Citizenship Number"
          className="full"
          name="citizenship_no"
          rules={[
            {
              required: true,
              message: "Citizenship number is required",
            },
          ]}
        >
          <Input placeholder="eg. 1234***"></Input>
        </Form.Item>
        <Form.Item
          label="Permanent Address"
          className="full"
          name="permanent_address"
          rules={[
            {
              required: true,
              message: "Permanent address is required",
            },
          ]}
        >
          <Input placeholder="eg. Morang, Nepal"></Input>
        </Form.Item>
        <Form.Item
          label="Kathmandu Contact Address"
          className="full"
          name="ktm_contact_address"
          rules={[
            {
              required: true,
              message: "Kathmandu contact address is required",
            },
          ]}
        >
          <Input placeholder="eg. Koteshwor"></Input>
        </Form.Item>
      </div>
      <div className="three-item">
        <Form.Item
          label="Contact Mobile"
          className="full"
          name="contact_mobile"
          rules={[
            {
              required: true,
              message: "Contact mobile is required",
            },
          ]}
        >
          <Input placeholder="eg. 98420*****"></Input>
        </Form.Item>
        <Form.Item label="Contact Landline" className="full" name="contact_landline">
          <Input placeholder="eg. 01-145***"></Input>
        </Form.Item>
        <Form.Item
          label="Email Address"
          className="full"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Email address is required & must be valid",
            },
          ]}
        >
          <Input placeholder="eg. ram@gmail.com"></Input>
        </Form.Item>
      </div>
      <br />

      <div className="flex ci">
        <Button htmlType="submit" style={{ width: 200 }} type="primary">
          Next
        </Button>
      </div>
    </Form>
  );
}
