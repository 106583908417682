import { axiosInstance } from "./axiosInstance";
import { BASE_URL } from "../configs";

export const execFileUpload = (payload) => {
  return axiosInstance.post(BASE_URL + "/v1/upload_file", payload);
};

export const execStoreForm = (payload) => {
  return axiosInstance.post(BASE_URL + "/v1/entrance_form", payload);
};

export const execReadMyForm = (payload) => {
  return axiosInstance.post(BASE_URL + "/v1/my_entrance_form", payload);
};

export const execReadOpenForms = () => {
  return axiosInstance.get(BASE_URL + "/v1/open_forms");
};

export const execCheckVoucherSubmit = (payload) => {
  return axiosInstance.post(BASE_URL + "/v1/check_voucher_submit", payload);
};
export const execVoucherSubmit = (payload) => {
  return axiosInstance.post(BASE_URL + "/v1/voucher_submit", payload);
};
