import React from "react";
import SubPageHead from "../../../components/SubPageHead/SubPageHead";
import { Steps, Modal } from "antd";
import GeneralDetails from "./GeneralDetails";
import AcademicRecord from "./AcademicRecord";
import FinancialSupport from "./FinancialSupport";
import Referees from "./Referees";
import WhySomtu from "./WhySomtu";
import { execStoreForm, execReadOpenForms } from "../../../api/apis";
import RequiredDocuments from "./RequiredDocuments";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { notifyError } from "../../../helper/notifiers";
import Loading from "../../../components/Loading";

const SuccessCard = (props) => {
  return (
    <div className="success-card text center">
      <div className="check">
        <CheckOutlined />
      </div>
      <p className="message">{props.message}</p>
      <p>
        Please store the <b>identification number</b> below, you will need it to check status of your form
      </p>
      <div className="idn">{props.identification_number}</div>
    </div>
  );
};

const FormClosedCard = (props) => {
  return (
    <div className="success-card text center">
      <div className="check" style={{ background: "#f5222d" }}>
        <CloseOutlined />
      </div>
      <p className="message">
        <b>{props.message}</b>
      </p>
      <p>
        <b>Please check back later</b>
      </p>
    </div>
  );
};

export default function EntryForm({ history }) {
  const entrance_form_data = localStorage.getItem("entrance_form_data");
  let temp_inp = {};
  if (entrance_form_data) {
    temp_inp = JSON.parse(entrance_form_data);
  }

  const [state, setState] = React.useState({
    data: null,
    loading: false,
  });

  const [inputs, setInputs] = React.useState(temp_inp);
  const [step, setStep] = React.useState(0);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const addInputs = (val) => {
    setInputs({ ...inputs, ...val });
    setStep((pS) => pS + 1);
  };

  const onPrevious = (val) => {
    if (step === 0) return;
    setInputs({ ...inputs, ...val });
    setStep((pS) => pS - 1);
  };

  const showSuccessModal = (res) => {
    Modal.info({
      title: " ",
      centered: true,
      icon: null,
      content: <SuccessCard {...res} />,
      okButtonProps: { style: { background: "teal", borderColor: "teal" } },
      onOk: () => {
        setInputs({});
        localStorage.removeItem("entrance_form_data");
        localStorage.removeItem("entrance_form_files");
        history.push("/");
      },
    });
  };

  const onFormClosed = (msg) => {
    Modal.info({
      title: " ",
      centered: true,
      icon: null,
      content: <FormClosedCard message={msg} />,
      okButtonProps: { style: { background: "#f5222d", borderColor: "#f5222d" } },
      onOk: () => {
        history.push("/");
      },
    });
  };

  const fetchFormStatus = async () => {
    setState({ data: null, loading: true });
    try {
      const res = await execReadOpenForms();
      setState({ data: res, loading: false });
    } catch (err) {
      setState({ data: null, loading: false });
      onFormClosed(err.message);
    }
  };

  const onSubmitForm = async (files) => {
    const payload = { ...inputs, ...files };
    setSubmitLoading(true);
    try {
      const res = await execStoreForm(payload);
      showSuccessModal(res);
      setSubmitLoading(false);
    } catch (err) {
      notifyError(err.message);
      setSubmitLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFormStatus();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (Object.keys(inputs).length > 0) {
      localStorage.setItem("entrance_form_data", JSON.stringify(inputs));
    }
  }, [inputs]);

  return (
    // F1F3F6
    <div className="entry-form" style={{background:'#F1F3F6'}}>
      <SubPageHead title="Entrance Form" />
      <br />

      {state.loading && <Loading />}
      {state.data && (
        <React.Fragment>
          <div className="wrap">
            <header>
              <h1>
                <b>Entrance Entry Form</b>
              </h1>
            </header>
            <br />
            <main>
              <Steps size="small" current={step}>
                <Steps.Step title="General Details"></Steps.Step>
                <Steps.Step title="Academic Record"></Steps.Step>
                <Steps.Step title="Financial Support"></Steps.Step>
                <Steps.Step title="Referees"></Steps.Step>
                <Steps.Step title="Why SOMTU"></Steps.Step>
                <Steps.Step title="Required Documents"></Steps.Step>
              </Steps>
              <br />
              <div className="content">
                {step === 0 && <GeneralDetails data={state.data} inputs={inputs} onNext={addInputs} />}
                {step === 1 && <AcademicRecord inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 2 && <FinancialSupport inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 3 && <Referees inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 4 && <WhySomtu inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 5 && <RequiredDocuments inputs={inputs} submitLoading={submitLoading} onSubmit={onSubmitForm} onPrevious={onPrevious} />}
              </div>
            </main>
          </div>
          <br />
          <br />
          <br />
        </React.Fragment>
      )}
    </div>
  );
}
