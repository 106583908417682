import React from "react";
import { Button, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { execFileUpload } from "../../../api/apis";
import { notifyError } from "../../../helper/notifiers";
import { FILE_BASE_URL } from "../../../configs";
import { Link } from "react-router-dom";

const blank_files = {
  pp_photo: null,
  citizenship_front: null,
  citizenship_back: null,
  slc_marksheet: null,
  slc_cc: null,
  plus2_marksheet: null,
  plus2_cc: null,
  bachelor_marksheet: null,
  bachelor_cc: null,
  bachelor_mc: null,
  selfi_with_citizenship: null,
  tu_equivalence: null,
};

export default function RequiredDocuments({ onPrevious, onSubmit, submitLoading }) {
  const [showSubmit, setShowSubmit] = React.useState(false);

  const entrance_form_files = localStorage.getItem("entrance_form_files");
  let temp_inp = {};
  if (entrance_form_files) {
    temp_inp = JSON.parse(entrance_form_files);
  }
  const [files, setFiles] = React.useState({ ...blank_files, ...temp_inp });

  const [loading, setLoading] = React.useState(false);

  const onSubmitForm = () => {
    let isOkay = true;
    if (!files.pp_photo) isOkay = false;
    if (!files.citizenship_front) isOkay = false;
    if (!files.citizenship_back) isOkay = false;
    if (!files.slc_marksheet) isOkay = false;
    if (!files.slc_cc) isOkay = false;
    if (!files.plus2_marksheet) isOkay = false;
    if (!files.plus2_cc) isOkay = false;
    if (!files.bachelor_marksheet) isOkay = false;
    if (!files.bachelor_cc) isOkay = false;
    if (!files.bachelor_mc) isOkay = false;
    if (!files.selfi_with_citizenship) isOkay = false;

    if (!isOkay) {
      notifyError("Please upload all the documents before submitting form");
    } else {
      const newFile = cleanFiles();
      onSubmit({ ...newFile });
    }
  };

  const cleanFiles = () => {
    const oldFiles = { ...files };
    let newFiles = {};

    Object.keys(oldFiles).forEach((item) => {
      if (oldFiles[item] && oldFiles[item].url) {
        newFiles[item] = oldFiles[item].url;
      }
    });

    return newFiles;
  };

  const onFileUpload = (name, res) => {
    setFiles((pF) => ({ ...pF, [name]: res }));
  };

  React.useEffect(() => {
    localStorage.setItem("entrance_form_files", JSON.stringify(files));
  }, [files]);

  return (
    <div>
      <header>
        <h1>
          <b>Required Documents</b>
        </h1>
      </header>
      <p>
        <b>Accepted File Format:</b> PNG, JPG, JPEG, PDF
      </p>
      <p>
        <b>Max File Size: </b> 1 MB
      </p>
      <p>
        <b>Note: </b> If in case you do not have one or more documents with you, you have to write a hand-written application mentioning why you do not have that specific document and upload that letter in the specific section. Read <Link to="/rules">rules</Link> here.
      </p>
      <br />
      <UploadItem loading={loading} setLoading={setLoading} data={files.pp_photo} pname="pp_photo" onUpload={onFileUpload} title="Passport Size Photo" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.citizenship_front} pname="citizenship_front" onUpload={onFileUpload} title="Citizenship Front" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.citizenship_back} pname="citizenship_back" onUpload={onFileUpload} title="Citizenship Back" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.slc_marksheet} pname="slc_marksheet" onUpload={onFileUpload} title="SLC or Equivalent Marksheet" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.slc_cc} pname="slc_cc" onUpload={onFileUpload} title="SLC or Equivalent Character Certificate" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.plus2_marksheet} pname="plus2_marksheet" onUpload={onFileUpload} title="+2/ A-Level or Equivalent Marksheet" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.plus2_cc} pname="plus2_cc" onUpload={onFileUpload} title="+2/ A-Level or Equivalent Character Certificate" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.bachelor_marksheet} pname="bachelor_marksheet" onUpload={onFileUpload} title="Bachelor Marksheet" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.bachelor_cc} pname="bachelor_cc" onUpload={onFileUpload} title="Bachelor Character Certificate" />
      <UploadItem loading={loading} setLoading={setLoading} data={files.bachelor_mc} pname="bachelor_mc" onUpload={onFileUpload} title="Bachelor Migration Certificate" />
      <br /> <br />
      <h2>
        <b>Upload your equivalence certificate (Optional)</b>
      </h2>
      <p>Applicable only if your degree is from foreign university</p>
      <UploadItem loading={loading} setLoading={setLoading} data={files.tu_equivalence} pname="tu_equivalence" onUpload={onFileUpload} title="Equivalence Certificate" />
      <br />
      <br />
      <h2>
        <b>Selfie with National Identity Card (NIC)</b>
      </h2>
      <p>NIC includes any government issues cards such as Citizenship, License, Voter’s Card etc.</p>
      <UploadItem loading={loading} setLoading={setLoading} data={files.selfi_with_citizenship} pname="selfi_with_citizenship" onUpload={onFileUpload} title="Selfie with National Identity Card (NIC)" />
      <br />
      <br />
      <p>I pledge that the information I have given in this application form is correct to the best of my knowledge. I will be solely responsible for all the documents and details provided by me. Also, I will provide further verification if required and comply with Terms & Conditions.</p>
      <div className="flex ci">
        <Checkbox
          onClick={(val) => {
            setShowSubmit(val.target.checked);
          }}
        >
          I have read and understood the condition <Link to="/rules">provided</Link> and agree to proceed forward.
        </Checkbox>
      </div>
      <br />
      <br />
      <div className="flex ci">
        <Button onClick={() => onPrevious({})} style={{ width: 200 }}>
          Previous
        </Button>
        <div className="hgap"></div>
        <Button disabled={!showSubmit} loading={submitLoading} type="primary" style={{ width: 200 }} onClick={onSubmitForm}>
          Submit Form
        </Button>
      </div>
    </div>
  );
}

const UploadItem = ({ loading, setLoading, title, data, pname, onUpload }) => {
  const fileRef = React.useRef(null);
  const rnn = localStorage.getItem("randomIdentifier") || Date.now().toString().substr(-4);


  const uploadFile = async () => {
    const file = fileRef.current.files[0];
    console.log(file);
    if (pname === "pp_photo" || pname === "selfi_with_citizenship") {
      if (file.name.includes(".pdf")) {
        notifyError("PDF can't be uploaded for Passport size photo and Selfie with National Identity Card, consider uploading image file");
        return;
      }
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_for", pname);
    formData.append("file_key", rnn);

    setLoading(true);
    try {
      const res = await execFileUpload(formData);
      const { id, url, name, ext } = res;
      console.log(ext);
      onUpload(pname, { id, url, name, ext });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notifyError(err.message || "Looks like your internet connection is slow, please try again");
    }
  };

  return (
    <div className="upload-item text center">
      <input type="file" onChange={() => uploadFile()} ref={fileRef} style={{ display: "none" }} />
      {data && (
        <React.Fragment>
          <aside>
            <a href={FILE_BASE_URL + data.url} target="_blank" rel="noopener noreferrer">
              <img style={{ width: 60 }} src={"/" + data.ext + ".png"} alt="" />
              <p style={{ marginTop: 7, fontSize: 12 }}>
                <b>{data.name}</b>
              </p>
            </a>
          </aside>
          <br />
        </React.Fragment>
      )}
      <p>
        <b>{title}</b>
      </p>
      <br />
      <Button onClick={() => fileRef.current.click()} shape="round" loading={loading} icon={<UploadOutlined />} type="primary"></Button>
    </div>
  );
};
