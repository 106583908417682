import React from "react";
import CkEditor from "../../../components/CkEditor/CkEditor";
import { Button } from "antd";
import { notifyError } from "../../../helper/notifiers";

export default function WhySomtu({ onNext, onPrevious, inputs }) {
  const [body, setBody] = React.useState("");

  React.useEffect(() => {
    const { why_somtu } = inputs;
    if (why_somtu) {
      setBody(why_somtu);
    }
    // eslint-disable-next-line
  }, []);

  const onNextPressed = () => {
    if (body.length > 3500) {
      onNext({ why_somtu: body });
    } else {
      notifyError("Why SOMTU body is too short");
    }
  };

  return (
    <div>
      <header>
        <h1>
          <b>Why SOMTU?</b>
        </h1>
        <p>write in atleast about 1000 words</p>
      </header>
      <br />
      <CkEditor body={body} onChange={(data) => setBody(data)} />
      <br />
      <div className="flex ci">
        <Button onClick={() => onPrevious({ why_somtu: body })} style={{ width: 200 }}>
          Previous
        </Button>
        <div className="hgap"></div>
        <Button type="primary" style={{ width: 200 }} onClick={onNextPressed}>
          Next
        </Button>
      </div>
    </div>
  );
}
